import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Congrats = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M18.1 1.9L15.4 1.9L15.4 1L4.6 1L4.6 1.9L1.9 1.9C1.36 1.9 1 2.26 1 2.8L1 4.96C1 7.03 2.53 8.74 4.6 9.01L4.6 9.1C4.6 11.71 6.4 13.87 8.83 14.41L8.2 16.3L6.13 16.3C5.77 16.3 5.41 16.57 5.32 16.93L4.6 19L15.4 19L14.68 16.93C14.59 16.57 14.23 16.3 13.87 16.3L11.8 16.3L11.17 14.41C13.6 13.87 15.4 11.71 15.4 9.1L15.4 9.01C17.47 8.74 19 7.03 19 4.96L19 2.8C19 2.26 18.64 1.9 18.1 1.9ZM4.6 7.21C3.61 6.94 2.8 6.04 2.8 4.96L2.8 3.7L4.6 3.7L4.6 7.21ZM11.8 10L10 9.01L8.2 10L8.65 8.2L7.3 6.4L9.19 6.4L10 4.6L10.81 6.4L12.7 6.4L11.35 8.2L11.8 10ZM17.2 4.96C17.2 6.04 16.39 7.03 15.4 7.21L15.4 3.7L17.2 3.7L17.2 4.96Z" />
  </StyledSvg>
);

Congrats.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Congrats;

