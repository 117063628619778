import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const CompanyRun = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M12.53,16.71v0c-0.15,0 -0.29,-0.05 -0.41,-0.15c-0.01,-0.01 -0.03,-0.03 -0.04,-0.04l-10.97,-11.2l-0.66,0.67c-0.29,0.3 -0.45,0.69 -0.45,1.1c0,0.42 0.16,0.81 0.45,1.1l6.77,6.9h-1.29c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h2.56l1.27,1.3h-5.52c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h7.05h10.08c0.35,0 0.63,-0.29 0.63,-0.65v-1.64h-9.47zM18.68,11.3h-1.6c-0.03,0.01 -0.05,0.02 -0.08,0.03l-3.1,0.97c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l2.36,-0.73l-0.51,-0.52l-3.01,0.94c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l3.15,-0.98c0.21,-0.97 -0.08,-1.98 -0.78,-2.69l-0.68,-0.69c-0.45,-0.46 -1.05,-0.71 -1.68,-0.71c-0.3,0 -0.58,-0.12 -0.78,-0.33l-0.42,-0.43c-0.21,-0.21 -0.33,-0.5 -0.33,-0.8c0,-0.65 -0.25,-1.26 -0.7,-1.72l-0.93,-0.95c-0.15,-0.16 -0.38,-0.22 -0.59,-0.17l-2.35,0.55c-0.12,0.03 -0.22,0.09 -0.31,0.17l-3.59,3.67l10.78,11.01h9.21v-0.72c0,-1.87 -1.49,-3.39 -3.32,-3.39z" />
  </StyledSvg>
);

CompanyRun.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default CompanyRun;
