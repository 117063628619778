import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const CombatSports = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 29'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M11.07 1C9.24 1 7.52 1.67 6.22 2.9C5.08 3.96 4.38 5.34 4.2 6.83L4.11 6.83C2.4 6.83 1 8.16 1 9.8L1 11.7C1 12.8 1.23 13.83 1.68 14.76C2.11 15.64 2.73 16.42 3.53 17.07C3.73 17.23 3.94 17.38 4.15 17.52L4.15 20.89C4.15 22.06 5.14 23 6.36 23L13.62 23C14.84 23 15.83 22.06 15.83 20.89L15.83 16.76C16.46 16.18 16.96 15.51 17.32 14.76C17.77 13.83 18 12.8 18 11.7L18 7.61C18 3.97 14.89 1 11.07 1ZM13.62 21.64L6.36 21.64C5.93 21.64 5.58 21.31 5.58 20.89L5.58 18.26C6.75 18.74 8.09 19 9.5 19C11.31 19 13.01 18.58 14.4 17.79L14.4 20.89C14.4 21.31 14.05 21.64 13.62 21.64ZM16.57 11.7C16.57 15.2 13.67 17.64 9.5 17.64C5.33 17.64 2.43 15.2 2.43 11.7L2.43 9.8C2.43 8.91 3.18 8.19 4.11 8.19L4.15 8.19L4.15 12L5.58 12L5.58 7.51C5.63 4.67 8.09 2.36 11.07 2.36C14.11 2.36 16.57 4.72 16.57 7.61L16.57 11.7L16.57 11.7Z" />
  </StyledSvg>
);

CombatSports.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default CombatSports;
