import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  max-width: 25px;
  min-width: 10px;
`;

const DateIcon = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path
      fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"}
      d="M11.78 8L6.94 8L6.94 12.8L11.78 12.8L11.78 8ZM10.16 11.2L8.55 11.2L8.55 9.6L10.16 9.6L10.16 11.2ZM13.39 1.6L12.58 1.6L12.58 0L10.97 0L10.97 1.6L4.52 1.6L4.52 0L2.91 0L2.91 1.6L2.1 1.6C1.21 1.6 0.5 2.32 0.5 3.2L0.49 14.4C0.49 15.28 1.21 16 2.1 16L13.39 16C14.28 16 15 15.28 15 14.4L15 3.2C15 2.32 14.28 1.6 13.39 1.6ZM13.39 14.4L2.1 14.4L2.1 5.6L13.39 5.6L13.39 14.4Z"
    />
  </StyledSvg>
);

DateIcon.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default DateIcon;
