import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  float: left;
  max-width: 25px;
  min-width: 10px;
`; 

const Wrapper = styled.span`
  width: 25px;
  margin-left:2px;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

const CommentIcon = ({color, marginTop, viewBox}) => (
  <Wrapper>
    <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 20 17'}>
      <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
        d="M0.06,8.82c0,4.32 4.48,7.82 10,7.82c1.3,0 2.53,-0.19 3.67,-0.54c1.16,0.9 3.12,2.15 5.03,2.15c-1,-0.94 -1.28,-2.77 -1.35,-4.13c1.64,-1.39 2.65,-3.26 2.65,-5.3c0,-4.32 -4.48,-7.82 -10,-7.82c-5.52,0 -10,3.5 -10,7.82z" />
    </StyledSvg>
  </Wrapper>
);

CommentIcon.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default CommentIcon;
