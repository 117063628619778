import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const BodyWeight = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 26'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M15.79 1L4.21 1C2.44 1 1 2.44 1 4.2L1 16.8C1 18.56 2.44 20 4.21 20L15.79 20C17.56 20 19 18.56 19 16.8L19 4.2C19 2.44 17.56 1 15.79 1ZM15.79 18.69L4.21 18.69C3.17 18.69 2.31 17.84 2.31 16.8L2.31 4.2C2.31 3.16 3.17 2.31 4.21 2.31L15.79 2.31C16.83 2.31 17.69 3.16 17.69 4.2L17.69 16.8L17.69 16.8C17.69 17.84 16.83 18.69 15.79 18.69ZM6.71 8.98L13.29 8.98C13.66 8.98 13.95 8.69 13.95 8.33C13.95 6.16 12.18 4.39 10 4.39C7.82 4.39 6.05 6.16 6.05 8.33C6.05 8.69 6.34 8.98 6.71 8.98ZM10 5.7C11.23 5.7 12.26 6.54 12.55 7.67L10.66 7.67L10.66 6.69C10.66 6.33 10.36 6.03 10 6.03C9.64 6.03 9.34 6.32 9.34 6.69L9.34 7.67L7.45 7.67C7.74 6.54 8.77 5.7 10 5.7Z" />
  </StyledSvg>
);

BodyWeight.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default BodyWeight;
