import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const NewCaloriesIcon = (props) => (
  <StyledSvg width={props.width || "21"} height={props.height || "28"} viewBox="0 0 21 28" fill={props.color || "none"} xmlns="http://www.w3.org/2000/svg">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill={props.color || "none"}/>
      <g mask="url(#mask0_2238_3151)">
        <path d="M9.76831 13.9238C9.17696 14.3737 7.95605 15.4863 7.95605 17.0022C7.95605 18.0029 8.76859 18.8171 9.76737 18.8171C10.7662 18.8171 11.5787 18.0029 11.5787 17.0022C11.5787 15.5666 10.3571 14.4017 9.76831 13.9238Z" fill={props.color || "none"}/>
        <path d="M14.0125 3.47266C13.7929 3.22434 13.4137 3.2093 13.1745 3.43251L10.4161 6.00591V0.616643C10.4161 0.16551 9.87193 -0.17156 9.46442 0.074846C9.38728 0.121487 7.55415 1.24039 5.70192 3.27231C3.44983 5.74294 1.91602 8.7511 1.91602 12.1247C1.91602 16.4626 5.4381 19.9917 9.76728 19.9917C8.12205 19.9917 6.78359 18.6505 6.78359 17.002C6.78359 14.3383 9.37094 12.761 9.48106 12.6951C9.67412 12.5798 9.91582 12.5852 10.1036 12.7088C10.2116 12.7801 12.7509 14.4825 12.7509 17.002C12.751 18.6505 11.4125 19.9917 9.76728 19.9917C14.0965 19.9917 17.6185 16.4626 17.6185 12.1247C17.6185 8.84915 16.1725 5.91325 14.0125 3.47266Z" fill={props.color || "none"}/>
      </g>
    </svg>
  </StyledSvg>

);

NewCaloriesIcon.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default NewCaloriesIcon;
