import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const NewDistanceIcon = (props) => (
  <StyledSvg width={props.width || "21"} height={props.height || "28"} viewBox="0 0 21 28" fill={props.color || "none"} xmlns="http://www.w3.org/2000/svg">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2238_3156" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_2238_3156)">
        <path d="M10 0C5.86429 0 2.5 3.48889 2.5 7.77778C2.5 11.7689 8.51714 18.8378 9.20286 19.6311C9.40643 19.8644 9.69571 20 10 20C10.3043 20 10.5936 19.8644 10.7971 19.6311C11.4829 18.8378 17.5 11.7689 17.5 7.77778C17.5 3.48889 14.1357 0 10 0ZM10 11.1111C8.22571 11.1111 6.78571 9.61778 6.78571 7.77778C6.78571 5.93778 8.22571 4.44444 10 4.44444C11.7743 4.44444 13.2143 5.93778 13.2143 7.77778C13.2143 9.61778 11.7743 11.1111 10 11.1111Z" fill="#69C2FF"/>
      </g>
    </svg>
  </StyledSvg>

);

NewDistanceIcon.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default NewDistanceIcon;
