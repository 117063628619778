import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Birthday = () => (
  <StyledSvg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 8C21.104 8 22 6.926 22 5.598C22 4.271 20 0 20 0C20 0 18 4.27 18 5.598C18 6.926 18.896 8 20 8Z" fill="#F39364"/>
    <path d="M8 8C9.104 8 10 6.926 10 5.598C10 4.271 8 0 8 0C8 0 6 4.27 6 5.598C6 6.926 6.896 8 8 8Z" fill="#F39364"/>
    <path d="M14 8C15.104 8 16 6.926 16 5.598C16 4.271 14 0 14 0C14 0 12 4.27 12 5.598C12 6.926 12.896 8 14 8Z" fill="#F39364"/>
    <path d="M21.797 18.798C21.231 18.367 20.43 18.12 19.602 18.12C18.773 18.12 17.972 18.367 17.406 18.798C16.486 19.495 15.277 19.881 14.001 19.881C12.727 19.881 11.518 19.495 10.597 18.798C10.03 18.367 9.22995 18.12 8.40195 18.12C7.57195 18.12 6.77195 18.367 6.20495 18.798C5.28495 19.495 4.07595 19.881 2.80195 19.881C2.52995 19.881 2.26495 19.86 2.00195 19.824V28H26.002V19.824C25.739 19.859 25.473 19.881 25.201 19.881C23.926 19.881 22.717 19.495 21.797 18.798Z" fill="#F39364"/>
    <path d="M26.001 12H21.001V9H19.001V12H15.001V9H13.001V12H9.00098V9H7.00098V12H2.00098C0.896977 12 0.000976562 12.896 0.000976562 14V18C0.173977 18.131 0.360977 18.241 0.555977 18.344C0.616977 18.376 0.680977 18.404 0.743977 18.434C0.881977 18.496 1.02398 18.554 1.16898 18.604C1.24398 18.629 1.31898 18.657 1.39698 18.679C1.57998 18.732 1.76898 18.774 1.95998 18.806C1.97398 18.808 1.98698 18.812 2.00098 18.814V18.815C2.26498 18.856 2.53198 18.88 2.80098 18.88C3.81398 18.88 4.82698 18.586 5.59998 18C6.37298 17.413 7.38698 17.119 8.39998 17.119C9.41298 17.119 10.427 17.413 11.2 18C11.974 18.586 12.987 18.88 14 18.88C15.014 18.88 16.027 18.586 16.801 18C17.574 17.413 18.588 17.119 19.602 17.119C20.615 17.119 21.628 17.413 22.402 18C23.174 18.586 24.189 18.88 25.201 18.88C25.47 18.88 25.737 18.856 26.002 18.814C26.012 18.812 26.021 18.81 26.03 18.809C26.224 18.777 26.416 18.733 26.603 18.68C26.683 18.656 26.76 18.628 26.838 18.601C26.981 18.552 27.118 18.497 27.252 18.436C27.318 18.405 27.385 18.375 27.448 18.342C27.641 18.239 27.829 18.13 28.001 18V14C28.001 12.896 27.105 12 26.001 12Z" fill="#F39364"/>
  </StyledSvg>
);

Birthday.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Birthday;
