import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Distance = (props) => (
  /*<StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'} width="25" height="25">
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M8.213,0.741 C3.999,0.741 0.570,4.154 0.570,8.349 C0.570,13.555 7.410,21.199 7.701,21.521 C7.975,21.825 8.452,21.824 8.725,21.521 C9.016,21.199 15.856,13.555 15.856,8.349 C15.856,4.154 12.428,0.741 8.213,0.741 ZM8.213,12.177 C6.093,12.177 4.368,10.460 4.368,8.349 C4.368,6.239 6.093,4.521 8.213,4.521 C10.334,4.521 12.059,6.239 12.059,8.349 C12.059,10.460 10.334,12.177 8.213,12.177 Z">
    </path>
  </StyledSvg>*/
  <StyledSvg width={props.width || "31"} height={props.height || "27"} viewBox="0 0 31 27" fill={props.color || "none"} xmlns="http://www.w3.org/2000/svg">
    <path d="M28.738 1.02511C28.4574 0.863051 28.1115 0.863051 27.8308 1.02511L21.2355 5.52482C21.1118 5.60841 21.0372 5.7475 21.0359 5.89677V25.0659C21.034 25.2346 21.1259 25.3905 21.2744 25.4705C21.423 25.5505 21.6037 25.5414 21.7435 25.4469L28.7653 20.7386C29.0373 20.573 29.2026 20.2768 29.2007 19.9584V1.81437C29.2002 1.48709 29.0233 1.18548 28.738 1.02511Z" fill={props.color || "none"}/>
    <path d="M10.1497 2.02311V21.2739C10.1493 21.4474 10.2479 21.6058 10.4037 21.6821L18.5685 25.6012C18.7092 25.6702 18.8756 25.6616 19.0085 25.5786C19.1414 25.4955 19.222 25.3497 19.2217 25.193V5.94222C19.222 5.76876 19.1234 5.6103 18.9676 5.53398L10.8028 1.61487C10.6621 1.54592 10.4957 1.55449 10.3629 1.63755C10.23 1.72061 10.1493 1.86639 10.1497 2.02311Z" fill={props.color || "none"}/>
    <path d="M8.33521 2.14995C8.32135 1.98937 8.22332 1.8482 8.0777 1.77912C7.93207 1.71005 7.76072 1.72343 7.62759 1.81429L0.614938 6.4773C0.339422 6.64065 0.170466 6.93719 0.17041 7.25749V25.4015C0.170467 25.7275 0.345393 26.0283 0.628638 26.1896C0.911882 26.351 1.2599 26.3479 1.54028 26.1817L8.13563 21.7001C8.26197 21.6147 8.33696 21.4716 8.33521 21.3191V2.14995Z" fill={props.color || "none"}/>

  </StyledSvg>
);

Distance.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default Distance;
